<template>
    <Pie
        :chart-data="chartDatas"
        :chart-id="chartId"
        :chart-options="chartOptions"
        :css-classes="cssClasses"
        :dataset-id-key="datasetIdKey"
        :height="height"
        :plugins="plugins"
        :styles="styles"
        :width="width"
    />
</template>

<script>
import {Pie} from 'vue-chartjs/legacy'

import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    CategoryScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)

export default {
    name: 'PieChart',
    components: {
        Pie
    },
    props: {
        chartId: {
            type: String,
            default: 'pie-chart'
        },
        datasetIdKey: {
            type: String,
            default: 'label'
        },
        width: {
            type: Number,
            default: 400
        },
        height: {
            type: Number,
            default: 400
        },
        cssClasses: {
            default: '',
            type: String
        },
        styles: {
            type: Object,
            default: () => {
            }
        },
        plugins: {
            type: Array,
            default: () => []
        },
        datas: {
            default: null,
            type: Map
        }
    },
    data() {
        return {
            colors: ['#005A8C', '#0077B8', '#0092E0', '#004266', '#00283D'],
            chartOptions: {
                responsive: false,
                maintainAspectRatio: false
            }
        }
    },
    computed: {
        chartDatas() {
            let res = {
                labels: [],
                datasets: [
                    {
                        backgroundColor: [],
                        data: []
                    }
                ]
            }

            let index = 0
            let backgroundColor = []
            let data = []
            this.datas?.forEach((value, key) => {
                res.labels.push(key)
                backgroundColor.push(this.colors[index])
                data.push(value.length)
                index++
            })

            res.datasets = [
                {
                    backgroundColor: backgroundColor,
                    data: data
                }
            ]

            return res
        }
    }
}
</script>
