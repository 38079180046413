<template>
    <div class="button-stats full-width">
        <v-data-table
            :headers="tableHeaders"
            :items="stats"
            :items-per-page="15"
            class="elevation-1"
        >
            <template v-slot:item.hasLoyalty="{ item }">
                {{ item.hasLoyalty === "1" ? "Yes" : "No" }}
            </template>
        </v-data-table>
    </div>
</template>

<script>
export default {
    name: "TextStats",
    props: ['stats'],
    data: () => {
        return {
            tableHeaders: []
        }
    },
    computed: {},
    methods: {
        generateTableHeaders() {
            let [statsToFilter] = this.statByGroupId().values();
            let dynamicHeaders = statsToFilter.map(stat => {
                return {
                    text: stat.input.value, value: 'textValue',
                }
            })

            this.tableHeaders = [
                {
                    text: this.$i18n.t('statsCreationDate'),
                    align: 'start',
                    sortable: true,
                    value: 'creationDate',
                },
                ...dynamicHeaders,
                {text: this.$i18n.t('statsHasLoyalty'), value: 'hasLoyalty'},
                {text: this.$i18n.t('statsAmount'), value: 'amount'},
                {text: this.$i18n.t('statsNbProduct'), value: 'nbProduct'},
            ]
        },
        statByGroupId() {
            let res = new Map()
            this.stats?.forEach(stat => {
                if (!res.has(stat.groupId)) {
                    res.set(stat.groupId, [stat])
                } else {
                    res.set(stat.groupId, [...res.get(stat.groupId), stat])
                }
            })

            return res
        },
    },
    watch: {
        stats() {
            console.log(this.stats)
            this.generateTableHeaders()
        }
    }
}
</script>

<style scoped>

</style>
