var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"global-stats full-width"},[(_vm.screen.templateType === 'QRCODE')?_c('span',{staticClass:"d-flex justify-center"},[_vm._v(_vm._s(_vm.$t('statsTotal', {nbStats: _vm.stats.length / 2 || 0})))]):_c('span',{staticClass:"d-flex justify-center"},[_vm._v(_vm._s(_vm.$t('statsTotal', {nbStats: _vm.stats.length || 0})))]),(_vm.isNotNull(_vm.screen) && _vm.screen.templateType === 'STARS')?_c('div',{staticClass:"d-flex flex-column align-center"},[_c('v-rating',{attrs:{"value":_vm.starsAverage,"color":"yellow","half-increments":"","hover":"","length":"5","readonly":"","size":"50"}}),_c('span',[_vm._v(_vm._s(_vm.starsAverage)+"/5")])],1):_vm._e(),(_vm.nbClick > 0)?_c('PieChart',{staticClass:"d-flex justify-center",attrs:{"datas":_vm.datasClick}}):_vm._e(),(_vm.screen.templateType == 'QRCODE')?[_c('div',{staticClass:"d-flex align-center"},_vm._l((_vm.datasText),function(dataValue,dataKey){return _c('fieldset',{key:dataKey,staticClass:"my-3 mx-2 d-flex flex-column align-start rounded-lg pa-5 full-width"},[_c('legend',{staticClass:"mx-3 px-2 text-caption"},[_vm._v(_vm._s(dataKey))]),_c('v-row',{staticClass:"full-width"},_vm._l((dataValue),function(value){return _c('v-col',{key:value.id,attrs:{"cols":"6","lg":"4"}},[_c('v-card',{staticClass:"rounded-lg my-2",attrs:{"max-width":"344","outlined":""}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h6 mb-1"},[_vm._v(" "+_vm._s(value.label.textValue))]),_c('v-list-item-subtitle',{staticClass:"text-caption"},[_c('p',[_c('span',{staticClass:"font-italic"},[_vm._v(_vm._s(_vm.$t("statsAmount"))+": "+_vm._s(parseFloat(value.value.amount).toFixed(2))+"€ ")]),_c('span',[_vm._v(" | "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({class:{'inactive-loyalty': value.hasLoyalty != '1'},attrs:{"x-small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-tag-heart")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t(value.hasLoyalty != '1' ? "statsHasNoLoyalty" : "statsHasLoyalty")))])])],1)])]),_c('v-list-item-subtitle',{staticClass:"text-body-1"},[_vm._v(_vm._s(value.value.textValue))]),_c('div',{staticClass:"text-end darkGrey--text font-italic text-caption"},[_vm._v(" "+_vm._s(_vm.formatDateAndHourEnToDateLocale(value.value.creationDate)))])],1)],1)],1)],1)}),1)],1)}),0)]:(_vm.screen.templateType == 'STARS')?[_c('div',{staticClass:"d-flex align-center"},_vm._l((_vm.datasText),function(ref){
var dataKey = ref[0];
var dataValue = ref[1];
return _c('fieldset',{key:dataKey,staticClass:"my-3 mx-2 d-flex flex-column align-start rounded-lg pa-5 full-width"},[_c('legend',{staticClass:"mx-3 px-2 text-caption"},[_vm._v(_vm._s(dataKey))]),_c('v-row',{staticClass:"full-width"},_vm._l((dataValue),function(value){return _c('v-col',{key:value.id,attrs:{"cols":"6","lg":"4"}},[_c('v-card',{staticClass:"rounded-lg my-2",attrs:{"max-width":"344","outlined":""}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('div',{staticClass:"d-flex align-center"},[_c('v-rating',{attrs:{"value":parseFloat(value.textValue),"readonly":"","small":""}}),_c('span',{staticClass:"text-end darkGrey--text font-italic text-caption"},[_vm._v(" ("+_vm._s(parseFloat(value.textValue))+"/5) ")])],1),_c('v-list-item-subtitle',[_c('p',[_c('span',{staticClass:"font-italic"},[_vm._v(_vm._s(_vm.$t("statsAmount"))+": "+_vm._s(parseFloat(value.amount).toFixed(2))+"€ ")]),_c('span',[_vm._v(" | "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({class:{'inactive-loyalty': value.hasLoyalty != '1'},attrs:{"x-small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-tag-heart")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t(value.hasLoyalty != '1' ? "statsHasNoLoyalty" : "statsHasLoyalty")))])])],1)])]),_c('div',{staticClass:"text-end darkGrey--text font-italic text-caption"},[_vm._v(" "+_vm._s(_vm.formatDateAndHourEnToDateLocale(value.creationDate)))])],1)],1)],1)],1)}),1)],1)}),0)]:[_c('div',{staticClass:"d-flex align-center"},_vm._l((_vm.datasText),function(ref){
var dataKey = ref[0];
var dataValue = ref[1];
return _c('fieldset',{key:dataKey,staticClass:"my-3 mx-2 d-flex flex-column align-start rounded-lg pa-5 full-width"},[_c('legend',{staticClass:"mx-3 px-2 text-caption"},[_vm._v(_vm._s(dataKey))]),_c('v-row',{staticClass:"full-width"},_vm._l((dataValue),function(value){return _c('v-col',{key:value.id,attrs:{"cols":"6","lg":"4"}},[_c('v-card',{staticClass:"rounded-lg my-2",attrs:{"max-width":"344","outlined":""}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h6 mb-1"},[_vm._v(" "+_vm._s(value.textValue))]),_c('v-list-item-subtitle',{staticClass:"text-caption"},[_c('p',[_c('span',{staticClass:"font-italic"},[_vm._v(_vm._s(_vm.$t("statsAmount"))+": "+_vm._s(parseFloat(value.amount).toFixed(2))+"€ ")]),_c('span',[_vm._v(" | "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({class:{'inactive-loyalty': value.hasLoyalty != '1'},attrs:{"x-small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-tag-heart")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t(value.hasLoyalty != '1' ? "statsHasNoLoyalty" : "statsHasLoyalty")))])])],1)])]),_c('div',{staticClass:"text-end darkGrey--text font-italic text-caption"},[_vm._v(" "+_vm._s(_vm.formatDateAndHourEnToDateLocale(value.creationDate)))])],1)],1)],1)],1)}),1)],1)}),0)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }