const axios = require('axios');
import {config} from '@/assets/js/Utils.js';

export function getAllByCampaign(campaignId){
    let path = "/stats/get/all/campaign?campaignId=" + campaignId

    return axios.get(config.SMART_ENGAGE_WS_URL + path)
}

export function getAllByCampaignAndScreen(campaignId, screenId){
    let path = "/stats/get/all/screen?campaignId=" + campaignId + "&screenId=" + screenId

    return axios.get(config.SMART_ENGAGE_WS_URL + path)
}

export function getAllByCampaignAndScreenAndInput(campaignId, screenId, inputId){
    let path = "/stats/get/all/input?campaignId=" + campaignId + "&screenId=" + screenId + "&inputId=" + inputId

    return axios.get(config.SMART_ENGAGE_WS_URL + path)
}
