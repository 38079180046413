<template>
    <div class="stats-overview-wrapper">
        <div class="my-4 d-flex flex-column">
            <v-btn text @click="panel = (panel-1)%2">
                <p class="ma-0 text-body-2 font-weight-medium">{{ $t("statsOverviewFiltersLabel") }}
                    <v-icon small>{{ panel == 0 ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
                </p>
            </v-btn>
            <v-expansion-panels v-model="panel" flat>
                <v-expansion-panel flat>
                    <v-expansion-panel-content color="#F0F6F9">
                        <v-row class="justify-center">
                            <v-col v-for="(filterItems, key) in filters" :key="`filter-${key}`" class="my-3 mx-2 rounded-lg" cols="3">
                                <p class="ma-0 text-body-1 font-weight-bold">{{ $t(`statsOverviewFilters.${key}Key`) }}</p>
                                <v-select v-model="selectedFilters[key]" :items="filterItems" chips dense hide-details multiple>
                                    <template v-slot:selection="{ item }">
                                        <v-chip v-if="item !== '' " class="my-1" small><span class="text-caption">{{ item }} </span></v-chip>
                                    </template>
                                </v-select>
                            </v-col>
                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </div>
        <div class="d-flex justify-center flex-wrap">
            <div class="mx-8 my-8">
                <p class="text-h4">{{ $t("statsOverviewFilters.satisfactionKey") }}</p>
                <Pie
                    :key="`satisfaction-${key}`"
                    :chart-data="satisfactionDataset"
                    :height="400"
                    :width="400"
                />
            </div>
            <div class="mx-8 my-8">
                <p class="text-h4">{{ $t("statsOverviewFilters.serviceRatingKey") }}</p>
                <Pie
                    :key="`service-${key}`"
                    :chart-data="serviceDataset"
                    :height="400"
                    :width="400"
                />
            </div>
            <div class="mx-8 my-8">
                <p class="text-h4">{{ $t("statsOverviewFilters.productRatingKey") }}</p>
                <Pie
                    :key="`product-${key}`"
                    :chart-data="productDataset"
                    :height="400"
                    :width="400"
                />
            </div>
            <div class="mx-8 my-8">
                <p class="text-h4">{{ $t("statsOverviewFilters.weekKey") }}</p>
                <LineChart
                    :key="`line-${key}`"
                    :chart-data="week"
                    :height="400"
                    :width="400"
                />
            </div>
            <div v-show="false" class="mx-3">
                <p class="text-h4">ProductRating</p>
                <Pie
                    :key="`product-${key}`"
                    :chart-data="productDataset"
                    :height="400"
                    :width="400"
                />
            </div>
        </div>

    </div>
</template>

<script>
import {Pie, Line as LineChart} from 'vue-chartjs/legacy'
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    CategoryScale,
    PointElement,
    LinearScale,
    LineElement
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale, PointElement, LinearScale, LineElement)

export default {
    components: {
        Pie,
        LineChart
    },
    data() {
        return {
            panel: -1,
            data: [
                {
                    "brand": "Alpha",
                    "region": "Nord",
                    "store": "Lille",
                    "amount": "0-20€",
                    "hasLoyalty": "Oui",
                    "week": 1,
                    "slot": "8h-12h",
                    "satisfaction": "Happy",
                    "serviceRating": 5,
                    "productRating": 4,
                    "comment": ""
                },
                {
                    "brand": "Alpha",
                    "region": "Sud",
                    "store": "Marseille",
                    "amount": "0-20€",
                    "hasLoyalty": "Non",
                    "week": 2,
                    "slot": "12h-14h",
                    "satisfaction": "Happy",
                    "serviceRating": 4,
                    "productRating": 5,
                    "comment": ""
                },
                {
                    "brand": "Alpha",
                    "region": "Centre",
                    "store": "Clermont",
                    "amount": "0-20€",
                    "hasLoyalty": "Oui",
                    "week": 3,
                    "slot": "14h-18h",
                    "satisfaction": "Neutral",
                    "serviceRating": 3,
                    "productRating": 2,
                    "comment": ""
                },
                {
                    "brand": "Alpha",
                    "region": "Ouest",
                    "store": "Nantes",
                    "amount": "20-50€",
                    "hasLoyalty": "Non",
                    "week": 1,
                    "slot": "18h-20h",
                    "satisfaction": "Neutral",
                    "serviceRating": 3,
                    "productRating": 4,
                    "comment": ""
                },
                {
                    "brand": "Alpha",
                    "region": "Sud",
                    "store": "Toulon",
                    "amount": "20-50€",
                    "hasLoyalty": "Oui",
                    "week": 2,
                    "slot": "8h-12h",
                    "satisfaction": "Neutral",
                    "serviceRating": 2,
                    "productRating": 3,
                    "comment": ""
                },
                {
                    "brand": "Alpha",
                    "region": "Ouest",
                    "store": "Angers",
                    "amount": "20-50€",
                    "hasLoyalty": "Non",
                    "week": 3,
                    "slot": "12h-14h",
                    "satisfaction": "Unhappy",
                    "serviceRating": 1,
                    "productRating": 1,
                    "comment": "Panne"
                },
                {
                    "brand": "Alpha",
                    "region": "Est",
                    "store": "Metz",
                    "amount": "50-100€",
                    "hasLoyalty": "Oui",
                    "week": 1,
                    "slot": "14h-18h",
                    "satisfaction": "Unhappy",
                    "serviceRating": 2,
                    "productRating": 1,
                    "comment": "Panne"
                },
                {
                    "brand": "Alpha",
                    "region": "Nord",
                    "store": "Lille",
                    "amount": "50-100€",
                    "hasLoyalty": "Non",
                    "week": 2,
                    "slot": "18h-20h",
                    "satisfaction": "Unhappy",
                    "serviceRating": 3,
                    "productRating": 1,
                    "comment": "Rapport Qualité / Prix"
                },
                {
                    "brand": "Alpha",
                    "region": "Nord",
                    "store": "Roubaix",
                    "amount": "50-100€",
                    "hasLoyalty": "Oui",
                    "week": 3,
                    "slot": "8h-12h",
                    "satisfaction": "Unhappy",
                    "serviceRating": 2,
                    "productRating": 1,
                    "comment": "Politesse"
                },
                {
                    "brand": "Beta",
                    "region": "Sud",
                    "store": "Marseille",
                    "amount": "50-100€",
                    "hasLoyalty": "Non",
                    "week": 1,
                    "slot": "12h-14h",
                    "satisfaction": "Happy",
                    "serviceRating": 5,
                    "productRating": 4,
                    "comment": ""
                },
                {
                    "brand": "Beta",
                    "region": "Centre",
                    "store": "Clermont",
                    "amount": "50-100€",
                    "hasLoyalty": "Oui",
                    "week": 2,
                    "slot": "14h-18h",
                    "satisfaction": "Happy",
                    "serviceRating": 4,
                    "productRating": 5,
                    "comment": ""
                },
                {
                    "brand": "Beta",
                    "region": "Ouest",
                    "store": "Nantes",
                    "amount": "50-100€",
                    "hasLoyalty": "Non",
                    "week": 3,
                    "slot": "18h-20h",
                    "satisfaction": "Neutral",
                    "serviceRating": 3,
                    "productRating": 2,
                    "comment": ""
                },
                {
                    "brand": "Beta",
                    "region": "Sud",
                    "store": "Toulon",
                    "amount": "0-20€",
                    "hasLoyalty": "Oui",
                    "week": 1,
                    "slot": "8h-12h",
                    "satisfaction": "Neutral",
                    "serviceRating": 3,
                    "productRating": 4,
                    "comment": ""
                },
                {
                    "brand": "Beta",
                    "region": "Ouest",
                    "store": "Angers",
                    "amount": "0-20€",
                    "hasLoyalty": "Non",
                    "week": 2,
                    "slot": "12h-14h",
                    "satisfaction": "Neutral",
                    "serviceRating": 2,
                    "productRating": 3,
                    "comment": ""
                },
                {
                    "brand": "Gama",
                    "region": "Est",
                    "store": "Metz",
                    "amount": "0-20€",
                    "hasLoyalty": "Oui",
                    "week": 3,
                    "slot": "14h-18h",
                    "satisfaction": "Unhappy",
                    "serviceRating": 4,
                    "productRating": 1,
                    "comment": ""
                },
                {
                    "brand": "Gama",
                    "region": "Nord",
                    "store": "Lille",
                    "amount": "20-50€",
                    "hasLoyalty": "Non",
                    "week": 1,
                    "slot": "18h-20h",
                    "satisfaction": "Unhappy",
                    "serviceRating": 2,
                    "productRating": 1,
                    "comment": "Panne"
                },
                {
                    "brand": "Gama",
                    "region": "Sud",
                    "store": "Toulon",
                    "amount": "20-50€",
                    "hasLoyalty": "Oui",
                    "week": 2,
                    "slot": "8h-12h",
                    "satisfaction": "Unhappy",
                    "serviceRating": 3,
                    "productRating": 1,
                    "comment": "Panne"
                },
                {
                    "brand": "Gama",
                    "region": "Ouest",
                    "store": "Angers",
                    "amount": "20-50€",
                    "hasLoyalty": "Non",
                    "week": 3,
                    "slot": "12h-14h",
                    "satisfaction": "Unhappy",
                    "serviceRating": 2,
                    "productRating": 1,
                    "comment": "Rapport Qualité / Prix"
                },
                {
                    "brand": "Gama",
                    "region": "Est",
                    "store": "Metz",
                    "amount": "50-100€",
                    "hasLoyalty": "Oui",
                    "week": 1,
                    "slot": "14h-18h",
                    "satisfaction": "Happy",
                    "serviceRating": 3,
                    "productRating": 5,
                    "comment": "Politesse"
                },
                {
                    "brand": "Gama",
                    "region": "Nord",
                    "store": "Lille",
                    "amount": "50-100€",
                    "hasLoyalty": "Non",
                    "week": 2,
                    "slot": "18h-20h",
                    "satisfaction": "Happy",
                    "serviceRating": 2,
                    "productRating": 5,
                    "comment": ""
                }
            ],
            selectedFilters: [],
            key: ""
        }
    },
    computed: {
        filterKeys() {
            return Object.keys(this.data[0])
        },
        satisfactionDataset() {
            let labels = ["Happy", "Neutral", "Unhappy"]
            let filteredData = this.filterTable()
            this.key = this.genKey()
            let res = {
                labels: labels,
                datasets: [
                    {
                        backgroundColor: ["#0092E0", "#0077B8", "#005A8C"],
                        data: labels.map(label => filteredData.reduce((acc, d) => d["satisfaction"] == label ? acc + 1 : acc, 0))
                    }
                ]
            }

            return res
        },
        serviceDataset() {
            let labels = Array.apply(null, Array(5)).map((item, id) => {
                return id + 1
            })
            let backgroundColor = labels.map((label, index) => {
                return this.hslToHex(201, 100, 44 - (index * 5))
            })
            let filteredData = this.filterTable()
            let res = {
                labels: labels,
                datasets: [
                    {
                        backgroundColor: backgroundColor,
                        data: labels.map(label => filteredData.reduce((acc, d) => d["serviceRating"] == label ? acc + 1 : acc, 0))
                    }
                ]
            }

            return res
        },
        productDataset() {
            let labels = Array.apply(null, Array(5)).map((item, id) => {
                return id + 1
            })
            let backgroundColor = labels.map((label, index) => {
                return this.hslToHex(201, 100, 44 - (index * 5))
            })
            let filteredData = this.filterTable()
            let res = {
                labels: labels,
                datasets: [
                    {
                        backgroundColor: backgroundColor,
                        data: labels.map(label => filteredData.reduce((acc, d) => d["productRating"] == label ? acc + 1 : acc, 0))
                    }
                ]
            }
            console.log(res);
            return res
        },
        week() {
            let labels = Array.apply(null, Array(3)).map((item, id) => {
                return id + 1
            })
            let filteredData = this.filterTable()
            let res = {
                labels: labels,
                datasets: [
                    {
                        label: this.$i18n.t('statsMeanProductRating'),
                        borderColor: "#0092E0",
                        backgroundColor: "#0092E0",
                        pointBackgroundColor: "white",
                        data: labels.map(label => {
                            let i = 0
                            let a = filteredData.reduce((acc, d) => {
                                if (d["week"] == label) {
                                    i += 1
                                    return acc + d["productRating"]
                                } else {
                                    return acc
                                }
                            }, 0)
                            return a / i
                        })
                    },
                    {
                        label: this.$i18n.t('statsMeanServiceRating'),
                        borderColor: "#004266",
                        backgroundColor: "#004266",
                        pointBackgroundColor: "white",
                        data: labels.map(label => {
                            let i = 0
                            let a = filteredData.reduce((acc, d) => {
                                if (d["week"] == label) {
                                    i += 1
                                    return acc + d["serviceRating"]
                                } else {
                                    return acc
                                }
                            }, 0)
                            return a / i
                        })
                    }
                ]
            }
            return res
        },
        filters() {
            let a = {}
            this.filterKeys.forEach(element => {
                a[element] = Array.from(new Set(this.data.map(dataElmt => dataElmt[element])))
            });
            return a
        }
    },
    methods: {
        filterTable() {
            let filterKeys = Object.keys(this.selectedFilters)
            let res = this.data.map(item => {
                let goodVal = true
                filterKeys.forEach(filter => {
                    if (this.selectedFilters[filter].indexOf(item[filter]) < 0) {
                        goodVal = false
                    }
                })
                if (goodVal) {
                    return item
                } else {
                    return null
                }
            })
            return res.filter(i => i != null)
        },
        genKey() {
            return parseInt(Math.random() * 10000)
        }
    },
    created() {
        this.key = this.genKey();
    },
    watch: {
        filterKeys: {
            handler() {
                this.selectedFilters = Object.assign({}, this.filters)
            },
            immediate: true
        }
    },
}
</script>

<style scoped>
.filter-group {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.custom-input >>> .v-input__slot::before {
    border: none !important;
}
</style>
