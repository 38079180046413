<template>
    <div class="d-flex flex-column stats" v-if="isNotNull(campaign) && isNotNull(campaign.scenario)">
        <v-row class="mt-6">
            <v-col cols="9" class="pa-8">
                <div class="pa-5 d-flex flex-column rounded-xl full-height align-center justify-center screen-creation-wrapper">
                    <p class="ma-0 text-h5 primary--text font-weight-bold text-center" v-if="isNotNull(selectedScreen)">{{screenTitle}}</p>

                    <v-tabs v-model="tab" flat centered v-if="isNotNull(selectedScreen)">
                        <v-tabs-slider></v-tabs-slider>
                        <v-tab @click="changeSelectedInput(null)">Global</v-tab>
                        <v-tab @click="changeSelectedInput(input)" v-for="input in filterInputsToShow" :key="'btn_' + input.id">{{inputName(input)}}</v-tab>

                        <v-tabs-items class="my-3" v-model="tab">
                            <v-tab-item>
                                <v-card flat>
                                    <GlobalStats :stats="statsToShow()" :screen="selectedScreen"></GlobalStats>
                                </v-card>
                            </v-tab-item>
                            <v-tab-item v-for="input in filterInputsToShow" :key="`stats-tab-${input.id}`">
                                <v-card flat>
                                    <component :stats="statsToShow(input.action)" v-bind:is="input.component"></component>
                                </v-card>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-tabs>

                    <template v-else>
                        <p class="ma-0 mb-4 text-h5 primary--text font-weight-bold">{{$t('allStatsTitle', {campaignName: campaign.name})}}</p>
                        <StatsOverview></StatsOverview>
                    </template>
                </div>
            </v-col>

            <v-col cols="3" class="screens-list">
                <div class="px-5 d-flex flex-column align-center">
                    <div class="mb-3 d-flex align-self-start align-center full-width">
                        <p class="ma-0 text-h5 primary--text font-weight-bold">{{$t('statsScreens')}}</p>
                        <CustomButton @click="selectedScreen = null" :outlined="isNull(selectedScreen)" class="all-stats-btn">{{$t('showAllStats')}}</CustomButton>
                    </div>
                    <div class="my-2 pa-3 rounded-t-lg d-flex align-center full-width screen-selector-btn cursor-pointer" @click="changeSelectedScreen(screen)" :class="{'active-screen': selectedScreen && screen.id === selectedScreen.id }" v-for="screen in campaign.scenario.screens" :key="screen.id">
                        <div class="d-flex flex-column">
                            <div class="d-flex align-center">
                                <p class="mb-0 darkGrey--text text-body-2 font-weight-bold">{{screen.name}}</p>
                            </div>
                            <p class="mb-0 darkGrey--text text-caption font-italic font-weight-regular">{{$t("screensCreationDate", {date: formatDateAndHourEnToDateLocale(screen.creationDate)})}}</p>
                            <p class="mb-0 darkGrey--text text-caption font-italic font-weight-regular">{{$t("statsScreenType", { screenType: getScreenTypeName(screen.templateType) })}}</p>
                        </div>
                    </div>
                </div>
            </v-col>
        </v-row>
    </div>
    <div class="stats" v-else-if="isNull(this.campaign)">
        Veuillez sélectionner une campagne
    </div>
    <div class="stats" v-else>
        Un scénario doit être associé à la campagne
    </div>
</template>

<script>
import GlobalStats from "@/components/SmartEngage/Stats/GlobalStats";
import ButtonStats from "@/components/SmartEngage/Stats/ButtonStats";
import TextStats from "@/components/SmartEngage/Stats/TextStats";
import StatsOverview from "@/components/SmartEngage/Stats/StatsOverview";
const StatsService = require("@/services/StatsService.js")

export default {
    name: 'Stats',
    components: {StatsOverview, TextStats, ButtonStats, GlobalStats},
    data: (vm) => {
        return {
            campaign: null,
            selectedScreen: null,
            selectedInput: null,
            availableTemplateTypes: vm.$t('availableTemplateTypes'),
            stats: {
                statsByCampaign: null,
                statsByCampaignAndScreen: null,
                statsByCampaignAndScreenAndInput: null,
            },
            tab: 0
        }
    },
    computed: {
        filterInputsToShow(){
            let stats = this.stats.statsByCampaignAndScreen || [];
            console.log("0")
            console.log(stats)

            if(this.selectedScreen.templateType === 'QCM'){
                return this.inputsToShowQCM(stats)
            }

            let uniqueObjArray = [...new Map(stats.map(item => [item.input.id, item])).values()];
            console.log("1")
            console.log(uniqueObjArray)

            uniqueObjArray = uniqueObjArray.filter(item => item.action === "CLICK")
            console.log("2")
            console.log(uniqueObjArray)

            console.log("3")
            console.log(uniqueObjArray.map(item => {
                return Object.assign(item.input, { component: "button-stats", action: "CLICK" })
            }))
            return uniqueObjArray.map(item => {
                return Object.assign(item.input, { component: "button-stats", action: "CLICK" })
            })
        },
        statsToShow(){
            return (...actionTypes) => {
                let stats = this.isNotNull(this.stats.statsByCampaignAndScreenAndInput) ? this.stats.statsByCampaignAndScreenAndInput : this.stats.statsByCampaignAndScreen

                if(this.isNull(stats)){
                    return []
                }

                if(this.isNotNull(this.selectedInput)){
                    if(this.selectedScreen.templateType === "QCM"){
                        console.log(stats)
                        return stats.filter(stat => JSON.parse(stat.textValue).includes(this.selectedInput.value.id))
                    }else{
                        return stats.filter(stat => stat.input.value === this.selectedInput.value && actionTypes.includes(stat.action))
                    }
                }
                return stats
            }
        },
        screenTitle(){
            return this.selectedScreen.inputs.find(input => input.name === "TITLE")?.value || ""
        }
    },
    created(){
        this.updatePageInfo(this.$route.meta)

        if(this.isNotNull(this.$route.params.campaign)){
            this.campaign = this.$route.params.campaign
            sessionStorage.setItem("SMART_ENGAGE_CAMPAIGN", JSON.stringify(this.campaign))
        }else if(this.isNotNull(sessionStorage.getItem("SMART_ENGAGE_CAMPAIGN"))){
            this.campaign = JSON.parse(sessionStorage.getItem("SMART_ENGAGE_CAMPAIGN"))
        }

        /*if(this.isNotNull(this.campaign?.scenario?.screens)){
            this.selectedScreen = this.campaign.scenario.screens[0]
            this.getAllByCampaignAndScreen()
        }*/
    },
    methods: {
        changeSelectedScreen(screen){
            this.selectedInput = null
            this.stats = {
                statsByCampaign: null,
                statsByCampaignAndScreen: null,
                statsByCampaignAndScreenAndInput: null,
            },
            this.selectedScreen = screen
            this.getAllByCampaignAndScreen()
        },
        changeSelectedInput(input){
            this.selectedInput = input
            if(this.isNotNull(input)){
                if(this.selectedScreen.templateType !== 'QCM'){
                    this.getAllByCampaignAndScreenAndInput()
                }
            }else{
                this.getAllByCampaignAndScreen()
                this.stats.statsByCampaignAndScreenAndInput = null
            }
        },
        getAllByCampaign(){
            StatsService.getAllByCampaign(this.campaign.id).then(({status, data}) => {
                if(status === 200){
                    this.stats.statsByCampaign = data
                }
            })
        },
        getAllByCampaignAndScreen(){
            StatsService.getAllByCampaignAndScreen(this.campaign.id, this.selectedScreen.id).then(({status, data}) => {
                if(status === 200){
                    this.stats.statsByCampaignAndScreen = data
                }
            })
        },
        getAllByCampaignAndScreenAndInput(){
            StatsService.getAllByCampaignAndScreenAndInput(this.campaign.id, this.selectedScreen.id, this.selectedInput.id).then(({status, data}) => {
                if(status === 200){
                    this.stats.statsByCampaignAndScreenAndInput = data
                }
            })
        },
        getScreenTypeName(typeCode){
            let foundType = this.availableTemplateTypes.find(type => type.code === typeCode)
            if(foundType){
                return foundType.name
            }
            return null
        },
        inputsToShowQCM(stats){
            if(this.isNotNull(stats)){
                let answers = JSON.parse(stats[0].input.value)

                let inputs = []
                answers.forEach(answer => {
                    let input = Object.assign(JSON.parse(JSON.stringify(stats[0].input)), { component: "button-stats", action: "CLICK", value: answer })
                    inputs.push(input)
                })

                return inputs
            }
        },
        inputName(input){
            if(this.selectedScreen.templateType === 'QCM'){
                return input.value.value
            }
            return input.value
        }
    }
}
</script>
<style scoped>
.screen-selector-btn{
    border: 1px #DFDFDF solid;
    border-bottom: 3px var(--primary-color) solid;
}

.screen-creation-wrapper{
    box-shadow: 0px 0px 15px #9191911a;
}

.active-screen{
    background: #1749c81f;
}

.all-stats-btn{
    margin: 0 0 0 auto;
}
</style>
