<template>
    <div class="global-stats full-width">
        <span v-if="screen.templateType === 'QRCODE'" class="d-flex justify-center">{{ $t('statsTotal', {nbStats: stats.length / 2 || 0}) }}</span>
        <span v-else class="d-flex justify-center">{{ $t('statsTotal', {nbStats: stats.length || 0}) }}</span>

        <div v-if="isNotNull(screen) && screen.templateType === 'STARS'" class="d-flex flex-column align-center">
            <v-rating
                :value="starsAverage"
                color="yellow"
                half-increments
                hover
                length="5"
                readonly
                size="50"
            ></v-rating>
            <span>{{ starsAverage }}/5</span>
        </div>

        <PieChart v-if="nbClick > 0" :datas="datasClick" class="d-flex justify-center"></PieChart>

        <template v-if="screen.templateType == 'QRCODE'">
            <div class="d-flex align-center">
                <fieldset v-for="(dataValue, dataKey) in datasText" :key="dataKey" class="my-3 mx-2 d-flex flex-column align-start rounded-lg pa-5 full-width">
                    <legend class="mx-3 px-2 text-caption">{{ dataKey }}</legend>
                    <v-row class="full-width">
                        <v-col v-for="value in dataValue" :key="value.id" cols="6" lg="4">
                            <v-card class="rounded-lg my-2" max-width="344" outlined>
                                <v-list-item three-line>
                                    <v-list-item-content>
                                        <v-list-item-title class="text-h6 mb-1"> {{ value.label.textValue }}</v-list-item-title>
                                        <v-list-item-subtitle class="text-caption">
                                            <p>
                                                <span class="font-italic">{{ $t("statsAmount") }}: {{ parseFloat(value.value.amount).toFixed(2) }}€ </span>
                                                <span>
                                                    |
                                                    <v-tooltip right>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-icon v-bind="attrs" v-on="on" :class="{'inactive-loyalty': value.hasLoyalty != '1'}" x-small>mdi-tag-heart</v-icon>
                                                        </template>
                                                        <span>{{ $t(value.hasLoyalty != '1' ? "statsHasNoLoyalty" : "statsHasLoyalty") }}</span>
                                                    </v-tooltip>
                                                </span>
                                            </p>
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle class="text-body-1">{{ value.value.textValue }}</v-list-item-subtitle>
                                        <div class="text-end darkGrey--text font-italic text-caption"> {{ formatDateAndHourEnToDateLocale(value.value.creationDate) }}</div>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-card>
                        </v-col>
                    </v-row>
                </fieldset>
            </div>
        </template>
        <template v-else-if="screen.templateType == 'STARS'">
            <div class="d-flex align-center">
                <fieldset v-for="[dataKey, dataValue] in datasText" :key="dataKey" class="my-3 mx-2 d-flex flex-column align-start rounded-lg pa-5 full-width">
                    <legend class="mx-3 px-2 text-caption">{{ dataKey }}</legend>
                    <v-row class="full-width">
                        <v-col v-for="value in dataValue" :key="value.id" cols="6" lg="4">
                            <v-card class="rounded-lg my-2" max-width="344" outlined>
                                <v-list-item three-line>
                                    <v-list-item-content>
                                        <div class="d-flex align-center">
                                            <v-rating :value="parseFloat(value.textValue)" readonly small></v-rating>
                                            <span class="text-end darkGrey--text font-italic text-caption"> ({{ parseFloat(value.textValue) }}/5) </span>
                                        </div>
                                        <v-list-item-subtitle>
                                            <p>
                                                <span class="font-italic">{{ $t("statsAmount") }}: {{ parseFloat(value.amount).toFixed(2) }}€ </span>
                                                <span>
                                                    |
                                                    <v-tooltip right>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-icon v-bind="attrs" v-on="on" :class="{'inactive-loyalty': value.hasLoyalty != '1'}" x-small>mdi-tag-heart</v-icon>
                                                        </template>
                                                        <span>{{ $t(value.hasLoyalty != '1' ? "statsHasNoLoyalty" : "statsHasLoyalty") }}</span>
                                                    </v-tooltip>
                                                </span>
                                            </p>
                                        </v-list-item-subtitle>
                                        <div class="text-end darkGrey--text font-italic text-caption"> {{ formatDateAndHourEnToDateLocale(value.creationDate) }}</div>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-card>
                        </v-col>
                    </v-row>
                </fieldset>
            </div>
        </template>
        <template v-else>
            <div class="d-flex align-center">
                <fieldset v-for="[dataKey, dataValue] in datasText" :key="dataKey" class="my-3 mx-2 d-flex flex-column align-start rounded-lg pa-5 full-width">
                    <legend class="mx-3 px-2 text-caption">{{ dataKey }}</legend>
                    <v-row class="full-width">
                        <v-col v-for="value in dataValue" :key="value.id" cols="6" lg="4">
                            <v-card class="rounded-lg my-2" max-width="344" outlined>
                                <v-list-item three-line>
                                    <v-list-item-content>
                                        <v-list-item-title class="text-h6 mb-1"> {{ value.textValue }}</v-list-item-title>
                                        <v-list-item-subtitle class="text-caption">
                                            <p>
                                                <span class="font-italic">{{ $t("statsAmount") }}: {{ parseFloat(value.amount).toFixed(2) }}€ </span>
                                                <span>
                                                    |
                                                    <v-tooltip right>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-icon v-bind="attrs" v-on="on" :class="{'inactive-loyalty': value.hasLoyalty != '1'}" x-small>mdi-tag-heart</v-icon>
                                                        </template>
                                                        <span>{{ $t(value.hasLoyalty != '1' ? "statsHasNoLoyalty" : "statsHasLoyalty") }}</span>
                                                    </v-tooltip>
                                                </span>
                                            </p>
                                        </v-list-item-subtitle>
                                        <div class="text-end darkGrey--text font-italic text-caption"> {{ formatDateAndHourEnToDateLocale(value.creationDate) }}</div>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-card>
                        </v-col>
                    </v-row>
                </fieldset>
            </div>
        </template>
    </div>
</template>

<script>
import PieChart from "@/components/SmartEngage/Charts/PieChart";

export default {
    name: "GlobalStats",
    components: {PieChart},
    props: ['stats', 'screen'],
    data: () => {
        return {}
    },
    computed: {
        nbClick() {
            let clicksInput = this.stats?.filter(stat => stat.action === "CLICK")
            return this.isNotNull(clicksInput) ? clicksInput.length : 0
        },
        datasClick() {
            //return this.getStatsByAction("CLICK")
            if(this.screen.templateType === "QCM"){
                return this.datasForQCM()
            }
            return this.getStatsByInputType("BUTTON")
        },
        datasText() {
            let c = this.getStatsByAction("TEXT", "TEXTAREA")
            if (this.screen.templateType == "QRCODE") {
                let res = {}
                let b = this.getStatsGrouped()
                let input = null
                for (const [key, value] of Object.entries(b)) {
                    let a = {}
                    value.forEach(e => {
                        if (e.input.name == "QR_SMALL_INPUT") {
                            a.label = e
                        } else if (e.input.name == "QR_TEXTAREA") {
                            a.value = e
                            input = e.input
                        }
                    })
                    if (!res[input.value]) {
                        res[input.value] = []
                    }
                    res[input.value].push(a)
                }
                return res
            }
            return c
        },
        starsAverage() {
            let total = this.stats.reduce((acc, stat) => {
                return acc + parseInt(stat.textValue)
            }, 0)
            return this.stats.length > 0 ? parseFloat((total / this.stats.length).toFixed(2)) : 0
        }
    },
    methods: {
        getStatsByAction(...actions) {
            let statsByInputName = new Map()
            this.stats?.forEach(stat => {
                if (actions.includes(stat.action)) {
                    if (!statsByInputName.has(stat.input.value)) {
                        statsByInputName.set(stat.input.value, [stat])
                    } else {
                        statsByInputName.set(stat.input.value, [...statsByInputName.get(stat.input.value), stat])
                    }
                }
            })

            if (actions.includes("CLICK")) {
                this.screen.inputs.forEach(input => {
                    if (input.type === "BUTTON" && !statsByInputName.has(input.value)) {
                        statsByInputName.set(input.value, [])
                    }
                })
            }

            return statsByInputName
        },
        getStatsByInputType(...inputTypes) {
            let statsByInputName = new Map()

            this.screen.inputs.forEach(input => {
                if (inputTypes.includes(input.type)) {
                    let stats = this.stats.filter(stat => stat.input.id === input.id)
                    statsByInputName.set(input.value, stats)
                }
            })

            return statsByInputName
        },
        getStatsGrouped() {
            let b = Array.from(new Set(this.stats.map(t => t.screenGroupId)))
            let res = new Map()
            b.forEach(g => {
                res.set(g, this.stats.filter(t => t.screenGroupId === g))
            })
            console.log(res)
            return Object.fromEntries(res)
        },
        parseFloat(val) {
            return parseFloat(val).toFixed(2)
        },
        datasForQCM(){
            let stats = this.getStatsByInputType("BUTTON")

            let [firstKey] = stats.keys()
            let answers = JSON.parse(firstKey)
            let datas = new Map()

            answers.forEach(answer => {
                let values = []
                stats.get(firstKey).forEach(input => {
                    if(input.textValue.indexOf(answer.id) > 0){
                        values.push(input)
                    }
                })
                datas.set(answer.value, values)
            })

            return datas
        }
    }
}
</script>

<style scoped>
fieldset {
    width: fit-content;
}

.global-stats fieldset {
    width: 100% !important;
}

.inactive-loyalty {
    opacity: 0.2;
}
</style>
