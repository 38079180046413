<template>
    <div class="button-stats full-width">
        <v-data-table
            :headers="tableHeaders"
            :items="stats"
            :items-per-page="15"
            class="elevation-1"
        >
            <template v-slot:item.creationDate="{ item }">
                {{ formatDateAndHourEnToDateLocale(item.creationDate) }}
            </template>
            <template v-slot:item.hasLoyalty="{ item }">
                {{ item.hasLoyalty === "1" ? "Yes" : "No" }}
            </template>
        </v-data-table>
    </div>
</template>

<script>
export default {
    name: "ButtonStats",
    props: ['stats'],
    data: (vm) => {
        return {
            tableHeaders: [
                {
                    text: vm.$t('statsCreationDate'),
                    align: 'start',
                    sortable: true,
                    value: 'creationDate',
                },
                {text: vm.$t('statsHasLoyalty'), value: 'hasLoyalty'},
                {text: vm.$t('statsAmount'), value: 'amount'},
                /*{ text: vm.$t('statsNbProduct'), value: 'nbProduct' },*/
            ],
        }
    },
    computed: {},
    methods: {}
}
</script>

<style scoped>

</style>
